<template>
    <v-main class="grey lighten-3">
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="flex-wrap-reverse" :dense="$vuetify.breakpoint.smAndDown">
          <v-col sm="12" md="4" lg="4" xl="3">
          </v-col>
          
          <v-col sm="12" md="8" lg="7" xl="5">
            <!-- actual board section -->
            <v-sheet id = "boardSection" align="center" justify="center" >
              <v-card>
                <chessboard/> <!--------------------------------------------------------------- -->
              </v-card>
            </v-sheet>

            <!-- bottom action button section -->
            <v-bottom-navigation background-color="transparent" dark>
              <v-btn @click="send('new')" v-show="!overlay"><span>Eval</span><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn>
            </v-bottom-navigation>
          </v-col>

          <v-col xl="2" lg="2" v-show="$vuetify.breakpoint.mdAndUp"></v-col>

        </v-row>
      </v-container>
    </v-main>
</template>


<script>
import {chessboard} from 'vue-chessboard'
import 'vue-chessboard/dist/vue-chessboard.css'



export default {
  name: "engine",
  components: {
    chessboard
  },
  data() {
    return {
    };
  },
  mounted() {
    var stockfish = require('@/assets/stockfish.js');
    var engine = stockfish();
    var got_uci;
    var started_thinking;
    engine.onmessage = function (line)
{
    var match;
    console.log("Line: " + line)
    
    if (typeof line !== "string") {
        console.log("Got line:");
        console.log(typeof line);
        console.log(line);
        return;
    }
    
    if (!got_uci && line === "uciok") {
        got_uci = true;
        if (this.position) {
            this.send("position " + this.position);
            this.send("eval");
            this.send("d");
        }
        
        this.send("go ponder");
    } else if (!started_thinking && line.indexOf("info depth") > -1) {
        console.log("Thinking...");
        started_thinking = true;
        setTimeout(function ()
        {
            this.send("stop");
        }, 1000 * 10);
    } else if (line.indexOf("bestmove") > -1) {
        match = line.match(/bestmove\s+(\S+)/);
        if (match) {
            console.log("Best move: " + match[1]);
            process.exit();
        }
    }
};
  },
  methods: {
    send(str){
      console.log("Sending: " + str)
      this.engine.postMessage(str);
    }
  }
}
</script>